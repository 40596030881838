<template>
    <div>
        <p>
            <span class="h3"> {{ LessonEvaluationId }} </span>
        </p>

        <div class="profileBox profileBox--2">
            <div
                v-if="!isVisibleAnswer && !evaluationState"
                :class="
                    evaluationValue !== '$' || evaluationValue !== '$$'
                        ? 'lessonEvaluation'
                        : 'lessonEvaluationInput'
                "
            >
                <p class="profileBox__title2">
                    {{
                        `${
                            evaluationValue === "$" || evaluationValue === "$$"
                                ? ""
                                : "Тест пройден!"
                        }`
                    }}
                </p>
                <p class="profileBox__text2">
                    {{
                        `${
                            evaluationValue === "$" || evaluationValue === "$$"
                                ? ""
                                : "На сколько $ вы оцениваете этот урок?"
                        }`
                    }}
                </p>
                <div
                    class="lessonEvaluationCard"
                    v-if="evaluationValue !== '$' && evaluationValue !== '$$'"
                >
                    <p
                        :class="
                            evaluationValue === '$'
                                ? 'lessonEvaluationCard__content lessonEvaluationCard__content--bad lessonEvaluationCard__content--active'
                                : 'lessonEvaluationCard__content lessonEvaluationCard__content--bad'
                        "
                        @click="() => handleClickGrade('$')"
                    >
                        <span class="lessonEvaluationCard__contentImages">
                            <inline-svg :src="iconDollar"></inline-svg>
                        </span>
                        <span class="lessonEvaluationCard__contentText">
                            Бесполезный
                        </span>
                    </p>
                    <p
                        :class="
                            evaluationValue === '$$'
                                ? 'lessonEvaluationCard__content lessonEvaluationCard__content--active'
                                : 'lessonEvaluationCard__content'
                        "
                        @click="() => handleClickGrade('$$')"
                    >
                        <span class="lessonEvaluationCard__contentImages">
                            <inline-svg :src="iconDollar"></inline-svg>
                            <inline-svg :src="iconDollar"></inline-svg>
                        </span>
                        <span class="lessonEvaluationCard__contentText">
                            Нормальный
                        </span>
                    </p>
                    <p
                        :class="
                            evaluationValue === '$$$'
                                ? 'lessonEvaluationCard__content lessonEvaluationCard__content--good lessonEvaluationCard__content--active'
                                : 'lessonEvaluationCard__content lessonEvaluationCard__content--good'
                        "
                        @click="() => handleClickGrade('$$$')"
                    >
                        <span class="lessonEvaluationCard__contentImages">
                            <inline-svg :src="iconDollar"></inline-svg>
                            <inline-svg :src="iconDollar"></inline-svg>
                            <inline-svg :src="iconDollar"></inline-svg>
                        </span>
                        <span class="lessonEvaluationCard__contentText">
                            Полезный
                        </span>
                    </p>
                </div>
                <div
                    v-if="evaluationValue === '$' || evaluationValue === '$$'"
                    class="lessonEvaluation"
                >
                    <div class="form profileBox__form2">
                        <div class="formgroup">
                            <label class="text2 colorMirage">
                                Напишите, пожалуйста, что именно нам стоит улучшить, для
                                нас мега важно Ваше мнение🙏
                            </label>
                            <TextArea
                                class="lessonEvaluation__textArea"
                                rows="5"
                                placeholder="Введите текст"
                                :isError="isDescriptionLong"
                                errorText="Вы не можете отправить более 1000 символов в этом текстовом поле"
                                :optimalTextLength="200"
                                optimalLengthTooltip="Постарайтесь аргументировать свой ответ, но уместить его в 200 символов, для того, чтобы другим студентам было комфортно его читать."
                                :onChange="(event) => onChangeTextArea(event)"
                            />
                        </div>
                    </div>
                </div>
                <Button
                    :isDisabled="!evaluationValue"
                    class="profileBox__btn3"
                    :title="
                        nextLesson &&
                        !(
                            !nextLesson.hasAccesToLesson ||
                            (nextLesson.lesson.type === 'additional' &&
                                nextLesson.lessonData &&
                                (nextLesson.lessonData.additionalLessonStatusByDate ===
                                    'closeEarly' ||
                                    nextLesson.lessonData.additionalLessonStatusByDate ===
                                        'unknown'))
                        )
                            ? 'Следующий урок'
                            : 'Подтвердить'
                    "
                    :onClick="() => chooseProgram()"
                />
            </div>

            <div v-if="evaluationState" class="lessonEvaluation">
                <p class="profileBox__title2">
                    Благодарим за оценку
                </p>
                <img
                    class="lessonEvaluation__img"
                    alt="foldedHands"
                    src="@/assets/img/pages/study/lessonEvaluation/foldedHands.png"
                />
                <p class="profileBox__text2">
                    Спасибо за оценку!
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import ButtonVideoProblem from "@/views/components/ButtonVideoProblem/ButtonVideoProblem";
import StudyInfoProtector from "@/views/pages/study/studyInfoProtector/studyInfoProtector";
import TextArea from "@/views/components/TextArea/TextArea";

import iconDollar from "../../../../../assets/img/pages/study/lessonEvaluation/dollar.svg";

export default {
    name: "lessonEvaluation",

    components: {
        Button,
        TextArea,
        ButtonVideoProblem,
        StudyInfoProtector,
    },

    watch: {
        "$route.params.lesson_id": function() {
            this.updateLessonStore();
            this.updateLessonEvaluation();
        },
    },

    computed: {
        currentBlock: function() {
            return (
                (this.$store.getters.getUserProgress &&
                    this.$store.getters.getUserProgress.progress &&
                    this.$store.getters.getUserProgress.progress.data[0] &&
                    this.$store.getters.getUserProgress.progress.data[0].blocks[
                        Number(this.$route.params.block_priority) - 1
                    ]) ||
                {}
            );
        },

        lesson: function() {
            return (
                (this.$store.getters.getLesson && this.$store.getters.getLesson.lesson) ||
                {}
            );
        },

        nextLesson: function() {
            const currentLessonIndex = this.currentBlock.lessons
                .map((l) => Number(l.lesson.lesson_id))
                .indexOf(Number(this.$route.params.lesson_id));
            return this.currentBlock.lessons[currentLessonIndex + 1];
        },

        LessonEvaluationId() {
            return this.$store.getters.getLesson.lesson.lesson.lesson.title;
        },

        evaluationState() {
            if (
                this.$store.getters.getEvaluation.evaluation.lessonsEvaluation &&
                this.$store.getters.getLesson
            ) {
                return this.$store.getters.getEvaluation.evaluation.lessonsEvaluation.find(
                    (itemId) =>
                        itemId.lessonId ===
                        this.$store.getters.getLesson.lesson.lesson.lesson._id
                )
                    ? this.$store.getters.getEvaluation.evaluation.lessonsEvaluation.find(
                          (itemId) =>
                              itemId.lessonId ===
                              this.$store.getters.getLesson.lesson.lesson.lesson._id
                      ).lessonId
                    : null;
            }
        },
    },

    async beforeMount() {
        this.updateLessonStore();
        this.updateLessonEvaluation();
    },

    data() {
        return {
            iconDollar,
            isVisibleAnswer: false,
            isVisibleNextTest: false,
            isDescriptionLong: false,
            evaluationValue: "",
            badOptionText: "",
        };
    },

    methods: {
        onSendAnswer() {
            this.isVisibleAnswer = true;
        },

        handleClickGrade(value) {
            this.evaluationValue = value;
        },

        onChangeTextArea(event) {
            this.badOptionText = event;
        },

        onOpenNextLesson() {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.nextLesson.lesson.lesson_id}`
            );
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async updateLessonEvaluation() {
            const getEvaluation = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/evaluation"
            );

            if (getEvaluation && getEvaluation.data && getEvaluation.data.result) {
                await this.$store.dispatch("setEvaluation", getEvaluation.data);
            } else {
                console.error(
                    "Ошибка при поиске оценки урока: " + (getEvaluation.data.error || "")
                );
                await this.$store.dispatch("clearEvaluation");
            }
        },

        chooseProgram: async function() {
            const lessonId = this.$store.getters.getLesson.lesson.lesson.lesson._id;
            const bodyByEvaluationValue =
                this.badOptionText !== ""
                    ? {
                          value: this.evaluationValue,
                          text: this.badOptionText,
                      }
                    : {
                          value: this.evaluationValue,
                      };

            if (this.badOptionText.length > 1000) {
                this.isDescriptionLong = true;
            } else {
                this.isDescriptionLong = false;
            }
            if (this.badOptionText.length < 1000) {
                const response = await this.axios.post(
                    `/programs/${this.$route.params.slug}/evaluation/block/${
                        this.$store.getters.getUserProgress.progress.data[0].blocks[
                            Number(this.$route.params.block_priority) - 1
                        ].blockId
                    }/lesson/${lessonId}`,
                    bodyByEvaluationValue
                );
                if (response && response.data && response.data.result) {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "success",
                            text: "Ваша оценка успешно отправлена, благодарим 🙏",
                        },
                    });

                    this.updateLessonEvaluation();
                    if (this.nextLesson) {
                        if (
                            !(
                                !this.nextLesson.hasAccesToLesson ||
                                (this.nextLesson.lesson.type === "additional" &&
                                    this.nextLesson.lessonData &&
                                    (this.nextLesson.lessonData
                                        .additionalLessonStatusByDate === "closeEarly" ||
                                        this.nextLesson.lessonData
                                            .additionalLessonStatusByDate === "unknown"))
                            )
                        ) {
                            this.onOpenNextLesson();
                        }
                    }

                    const getProgress = await this.axios.get(
                        "/programs/" + this.$route.params.slug + "/study/progress"
                    );

                    if (getProgress && getProgress.data && getProgress.data.result) {
                        await this.$store.dispatch("setUserProgress", getProgress.data);
                    } else {
                        console.error(
                            "Ошибка при поиске основных блоков: " + getProgress.data.error
                        );
                        await this.$store.dispatch("clearUserProgress");
                    }
                } else {
                    this.onSendAnswer();
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: "Не удалось отправить оценку, попробуйте позже",
                        },
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
@import "./lessonEvaluation.scss";
</style>
